<template>
  <jf-layout>

    <template slot="head">
      <el-page-header @back="$router.go(-1)" :content="$route.query.tenantName">
      </el-page-header>

      <el-form class="form" style="margin-top:10px" :inline="true" size="small">
        <el-form-item label="任务名称">
          <el-input v-model="params.taskName" :clearable="false"></el-input>
        </el-form-item>
        <el-form-item label="任务状态">
          <el-select v-model="params.isSettle" type="month" clearable>
            <el-option label="失败" :value="0"></el-option>
            <el-option label="成功" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务月份">
          <el-date-picker v-model="params.taskMonth" type="month" :clearable="false" value-format="yyyy-MM"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="hint">
        <div>已选中<span class="c-warning">{{selectList.length}}</span>条</div>
        <div>
          <el-button size="mini" type="danger" v-if="isAuth('Slideshow:tbslideshow:delete')" :disabled="!selectList.length" @click="checkout()">重新结算</el-button>
        </div>
      </div>
    </template>
    <el-table slot="body" v-loading="loading" border :data="list" ref="multipleTable" @selection-change="getSelect">
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" prop="tenantName" label="组织名称" />
      <el-table-column align="center" prop="userName" label="成员姓名" />
      <el-table-column align="center" prop="employee" label="成员编号" />
      <el-table-column align="center" prop="taskName" label="任务名称" />
      <el-table-column align="center" prop="settleTime" label="最后结算日期" />
      <el-table-column align="center" prop="realName" label="状态">
        <template slot-scope="{row}">
          <el-tag size="mini" type="info" v-if="row.isSettle===0">未结算</el-tag>
          <template v-if="row.isSettle===1">
            <el-tag v-if="row.isAbandon" size="mini" type="danger">已作废</el-tag>
            <el-tag v-else size="mini" type="success">已结算</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="{row}">

          <el-button size="mini"  @click="checkout(row)">重新结算</el-button>
        </template>
      </el-table-column>
    </el-table>
    <jf-page slot="foot" @change="getList()" :total="total" :page.sync="params.page" :limit.sync="params.limit" />

  </jf-layout>
</template>

<script>

export default {

  data() {
    return {
      show: false,
      activeId: null,
      total: 0,
      params: {
        page: 1,
        limit: 10,
        taskMonth: null,
        taskName: '',
        isSettle: null
      },
      list: [],
      loading: false,
      selectList: [],
    };
  },
  created() {
    this.params.taskMonth = this.$route.query.taskMonth;
    this.params.tenantCode = this.$route.query.tenantCode;
    this.getList();
  },
  methods: {
    checkout(row) {

      let data = {
        tenantCode: this.params.tenantCode,
        taskMonth: this.params.taskMonth
      }
      if (row) {
        data.userIdList = [row.userId];
      } else {
        data.userIdList = this.selectList.map(item => item.userId);
      }
      this.$confirm("确定重新结算?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {

          this.$del("/platform-config/taskrecordmembermonitoring/tenant/settle", data).then(res => {
            if (res && res.code == 0) {
              this.$message.success("结算成功！");
              this.getList();
            }
          })


        })
        .catch(() => { });
    },
    search() {
      this.page = 1;
      this.getList();
    },
    getMonth() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() - 0 + 1;
      return year + "-" + month;
    },
    // 获取列表
    getList() {
      let data = { ... this.params }
      for (var i in data) {
        if (data[i] === "") delete data[i]
      }
      this.loading = true;
      this.$get(
        "/platform-config/taskrecordmembermonitoring/info/page",
        data
      ).then(res => {
        if (res && res.code == 0) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      }).finally(() => {
        this.loading = false;
      })

    },
    // 选择table
    getSelect(list) {
      this.selectList = list;
    },

  }

};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__label {
  line-height: 40px;
}
.hint {
  display: flex;
  justify-content: space-between;
  background: #fff4e3;
  border-radius: 5px;
  padding: 0 17px;
  margin-top: 15px;
}
</style>
